<template>
  <div class="admin-wrap">
    <AdminMenu active-item="treatment-types" />
    <div class="admin-main">
      <AdminHeader title="服務管理：類別" backlink="javascript:history.back()" />
      <div class="service-type-add">
        <div class="service-type-add__add">
          <h3>{{title}}</h3>
          <div class="service-type-add__row">
            <label for="type">類別名稱</label>
            <input type="text" id="type" v-model="treatmentType.name">
            <button class="service-type-add__btn-save" @click="onSave">儲存</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import {
  apiGetTreatmentType,
  apiSaveTreatmentType
} from '@/api/v1/admin'

export default {
  name: 'TreatmentTypeDetail',
  props: ['treatmentTypeId'],
  components: { AdminMenu, AdminHeader },
  created () {
    this.fetchData()
  },
  data () {
    return {
      treatmentType: {
        id: 0,
        name: ''
      }
    }
  },
  computed: {
    title () {
      return (this.treatmentTypeId > 0 ? '編輯類別' : '新增類別')
    }
  },
  methods: {
    fetchData () {
      if (this.treatmentTypeId > 0) {
        apiGetTreatmentType(this.treatmentTypeId).then(data => {
          if (data.error) {
            console.log(data.error.message)
          } else {
            this.treatmentType = data
          }
        })
      }
    },
    onSave () {
      // TODO: verify input
      const params = {
        id: this.treatmentTypeId,
        name: this.treatmentType.name
      }
      console.log(params)
      apiSaveTreatmentType(params).then(data => {
        if (data.error) {
          this.$swal('儲存失敗', data.message, 'warning')
        } else {
          this.$swal('儲存完成', '', 'success').then(() => history.back())
        }
      })
    }
  }
}
</script>

<style>

</style>
